import React from 'react';
import Container from '@material-ui/core/Container';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

function Privacy({ data, location }) {
  const siteTitle = data.site.siteMetadata.title;
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Privacy Policy of ChordSL.com" />
      <Container maxWidth="md">
        <h1 id="ChordSLcom-is-here">Privacy policy</h1>

        <h2>Personal Information</h2>
        <p>
          The site does not collect personal information about site visitors
          without their knowledge. Visitors may opt-in to become a member of the
          site and if they wish provide true and valid information about
          themselves in order to build a better community within the site or may
          choose to provide personal information which may be deemed inaccurate.
          No matter what form of information visitors provide, the site is
          committed to protecting the privacy of its members and the information
          provided. The site does not sell or trade your personal information
          with any third party unless otherwise specified.
        </p>

        <h2>Visitor logging</h2>
        <p>
          he site maintains logs of users that contain information such as your
          IP address, operating system, browser information, etc. This
          information is purely used to identify visitor density given
          demographic areas and technologies mostly used to access the site.
          Also logging is used to make possible the various security
          functionally used to protect site content.
        </p>

        <h2>Third Party Advertising</h2>
        <p>
          The site uses an assortment of third party advertising companies that
          display advertisements targeted to visitors based on their country,
          language etc. If you click on any of these advertisements and are
          taken to another site outside of chordsl.com you agree that
          chordsl.com is not liable for any information you provide or any
          informtion captured on the advertisers site. We have contracted with
          Google Adsense to monitor certain pages of our website for the purpose
          of reporting website traffic, statistics, advertisement
          ‘click-throughs’, and/or other activities on the site. Where
          authorized by us, Google Adsense, and its business partners may use
          cookies, web beacons, and/or other monitoring technologies to compile
          anonymous statistics about our website visitors. No personally
          identifiable information is collected by or transferred to any party
          other than the Advertiser. For more information about how the
          information is collected and used please follow the links for
          Google(Adsense) privacy policy on
          http://www.google.com/privacypolicy.html.
        </p>

        <h2>Disclaimer</h2>
        <p>
          Please note that www.chordsl.com is merely an information search and
          presentation tool provided free of charge to site visitors.
          www.chordsl.com does not guarantee the correctness and validity of the
          chords or lyrics provided on the site and assumes absolutely no
          responsibility or liability whatsoever for any effect that this site
          has on you, your sanity, computer, spouse, children, musical
          instruments, speakers, pets or anything else related to you or your
          existence. No warranty is provided nor implied with the information
          provided on www.chordsl.com. The accuracy of the information contained
          is subject to conjecture. Use at your own risk.
        </p>
      </Container>
    </Layout>
  );
}

export default Privacy;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
